import React, { useState } from "react";
import Teleport from "../Custom/Teleport";
import { ClipLoader } from "react-spinners";
import { addCategoryOrDeck } from "../../endpoints/FlashCards/functions";
import { Button, TextField } from "@mui/material";
const AddCategory = (props) => {
  const { openModal, setOpenModal } = props;
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  function toggle() {
    if (openModal === "active") {
      setOpenModal("");
      setName("");
    } else {
      setOpenModal("active");
    }
  }
  async function handleAdd(name) {
    setLoading(true);
    try {
      await addCategoryOrDeck("categories", props.token, name, null);
      setName("");
      await props.getCategories();
    } catch (error) {
      console.log("Error occured at handleAdd", error);
    }
    setLoading(false);
    setOpenModal("");
  }
  return (
    <Teleport>
      <div className={openModal} id="add-modal">
        <div className="header">
          <h1>Add Category</h1>
        </div>
        <div className="modal-body">
          <TextField
            name="title"
            sx={{ width: "100%" }}
            inputProps={{ maxLength: 15 }}
            value={name}
            color="black"
            type="text"
            label="Name"
            onChange={(e) => setName(e.target.value)}
            onKeyDownCapture={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAdd(name);
              }
            }}
          />
          <div className="float-actions gap-2 d-flex justify-content-center">
            <Button
              onClick={toggle}
              color="ghostBlack"
              variant="contained"
              sx={{ maxWidth: "10rem", width: "100%" }}
              disableElevation
            >
              Cancel
            </Button>
            <Button
              color="black"
              className="px-14"
              variant="contained"
              disableElevation
              sx={{ maxWidth: "10rem", width: "100%" }}
              onClick={() => handleAdd(name)}
              disabled={loading || name === ""}
            >
              {!loading ? (
                "Add"
              ) : (
                <ClipLoader
                  color={"#ffffff"}
                  loading={loading}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className={openModal} id="add-overlay"></div>
    </Teleport>
  );
};

export default AddCategory;
