import { useNavigate } from "react-router-dom";
import * as request from "../axios";
import axios from "axios";

export async function getAllExams(token) {
  try {
    return await request.axiosGet(
      "https://rezin.pythonanywhere.com/exams/",
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getExamQuestions(examSelected) {
  try {
    return await request.getNoAuth(
      "https://rezin.pythonanywhere.com/questions/",
      { exam: examSelected }
    );
  } catch (error) {
    console.log(error);
    useNavigate("/");
  }
}

export async function getQuestionsCount(selectedExam) {
  try {
    return await request.getNoAuth(
      "https://rezin.pythonanywhere.com/exams/count",
      { exam: selectedExam }
    );
  } catch (error) {
    console.log(error);
    useNavigate("/");
  }
}

export async function deleteExamQuestion(id, token) {
  try {
    return await request.axiosDelete(
      `https://rezin.pythonanywhere.com/questions/${id}`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getQuestionAmount(exam, token) {
  try {
    return await request.axiosGet(
      "https://rezin.pythonanywhere.com/exams/count",
      token,
      { exam: exam }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteUserExam(id, token) {
  try {
    return await request.axiosDelete(
      `https://rezin.pythonanywhere.com/exams/${id}`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addNewExam(exam, token) {
  try {
    return await request.axiosPost(
      "https://rezin.pythonanywhere.com/exams/",
      exam,
      token
    );
  } catch (error) {
    console.log(error);
  }
}
export async function getExamById(id, token) {
  try {
    return await request.axiosGet(
      `https://rezin.pythonanywhere.com/exams/${id}`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}
export async function editExam(id, token, exam) {
  try {
    return await request.axiosPut(
      `https://rezin.pythonanywhere.com/exams/${id}`,
      exam,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateProfilePassword(token, data) {
  try {
    return await request.axiosPut(
      "https://rezin.pythonanywhere.com/auth/password/",
      data,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAllResources(choice, token) {
  try {
    return await request.axiosGet(
      "https://rezin.pythonanywhere.com/resources/",
      token,
      { exam: choice }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteResourceById(id, token) {
  try {
    return await request.axiosDelete(
      `https://rezin.pythonanywhere.com/resources/${id}/`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function createChoices(data, token) {
  try {
    return await request.axiosPost(
      "https://rezin.pythonanywhere.com/questions/choices",
      data,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function createQuestionWithImage(data, token) {
  try {
    return await request.axiosPostWithFile(
      "https://rezin.pythonanywhere.com/questions/images",
      data,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function createBulkQuestions(data, token) {
  try {
    return await request.axiosPost(
      "https://rezin.pythonanywhere.com/questions/",
      [data],
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function editQuestion(id, data, token) {
  try {
    return await request.axiosPutWithFile(
      `https://rezin.pythonanywhere.com/questions/${id}`,
      data,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function editChoices(id, data, token) {
  try {
    return await request.axiosPut(
      `https://rezin.pythonanywhere.com/questions/choices/${id}`,
      data,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getQuestionById(id, token) {
  try {
    return await request.axiosGet(
      `https://rezin.pythonanywhere.com/questions/${id}`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}
export async function properQuestionsCheck(exam, token) {
  try {
    return await request.axiosGet(
      `https://rezin.pythonanywhere.com/exams/validation`,
      token,
      { exam: exam }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function forgotPassword(body) {
  try {
    return await axios.patch(
      "https://rezin.pythonanywhere.com/auth/forgot/",
      body
    );
  } catch (error) {
    alert("Username or email was not found. Please try again.");
  }
}

export async function addResource(item, token) {
  try {
    return await request.axiosPostWithFile(
      "https://rezin.pythonanywhere.com/resources/",
      item,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function bulkEditQuestions(id, data, token) {
  try {
    return await request.axiosPut(
      `https://rezin.pythonanywhere.com/questions/bulkUpdate/${id}`,
      data,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function bulkDeleteQuestions(data, token) {
  try {
    return await request.axiosDelete(
      `https://rezin.pythonanywhere.com/questions/bulkDelete`,
      token,
      { delete: data }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function bulkAddQuestions(data, token) {
  for (let i = 0; i < data.length; i++) {
    try {
      const questionOnly = {
        exam_id: data[i].exam_id,
        question: data[i].question,
      };
      const question = await request.axiosPost(
        "https://rezin.pythonanywhere.com/questions/",
        [questionOnly],
        token
      );
      data[i].choices.forEach(async (choice) => {
        choice["question"] = question[0].id;
      });
    } catch (error) {
      console.log(error);
    }
  }
  try {
    const choicesOnly = data.map((question) => question.choices).flat();
    await createChoices(choicesOnly, token);
  } catch (error) {
    console.log(error);
  }
}
