import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  TextField,
} from "@mui/material";
import { AddBoxRounded, Delete } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  addNewExam,
  addResource,
  bulkAddQuestions,
  bulkDeleteQuestions,
  bulkEditQuestions,
  deleteResourceById,
  deleteUserExam,
  editExam,
  getExamById,
} from "../../endpoints/Assessment/functions";
import { defaultExamData, setNewExamData } from "../../context/initialValues";
import { ClipLoader } from "react-spinners";
import DisplayQuestions from "../../components/DisplayItems/DisplayQuestions";
import DisplayResources from "../../components/DisplayItems/DisplayResources";
import { trashStyles } from "../../context/customStyles";
import ResourceModal from "../../components/Modals/ResourceModal";
import ModifyQuestion from "../../components/Modals/ModifyQuestion";

const ModExamPage = (props) => {
  const { examId } = useParams();
  let localBackUp = null;
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const [exam, setExam] = useState(defaultExamData(props.user.id));
  const [questionSelectedId, setQuestionSelectedId] = useState(null);
  const [resources, setResources] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [index, setIndex] = useState(null);
  const [showResourceModal, setShowResourceModal] = useState("");
  const [showQuestionModal, setShowQuestionModal] = useState("");
  const visibleQuestion = questions.filter((item) => !item.delete);
  const visibleResources = resources.filter((item) => !item.delete);
  const [changesMade, setChangesMade] = useState({
    exam: false,
    resources: false,
    questions: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (examId !== "new") {
        setLoading(true);
        try {
          const response = await getExamById(examId, props.token);
          setExam({
            id: response.exam.id,
            creator_id: response.exam.creator.id,
            name: response.exam.name,
            description: response.exam.description,
            logo: response.exam.logo,
          });
          setResources(response.resources);
          setQuestions(response.questions);
        } catch (error) {
          console.log("Error occured at ModExamPage", error);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [examId]);

  async function saveExamDetails() {
    try {
      if (examId !== "new" && changesMade.exam) {
        await editExam(examId, props.token, exam);
      } else if (examId === "new") {
        const res = await addNewExam(exam, props.token);
        setExam(setNewExamData(res.id, exam));
        localBackUp = res.id;
      }
    } catch (error) {
      console.log("Error occured at saveExamDetails", error);
    }
  }

  async function handleResources() {
    try {
      const adding = resources.filter((item) => item.localLink && !item.delete);
      const deleting = resources.filter(
        (item) => item.delete && !item.localLink
      );
      if (adding.length && (exam.id || localBackUp)) {
        for (let i = 0; i < adding.length; i++) {
          const formData = new FormData();
          formData.append("file", adding[i].file);
          formData.append("name", adding[i].name);
          formData.append("related_exam_id", exam.id || localBackUp);
          await addResource(formData, props.token);
        }
      }
      if (deleting.length) {
        for (let i = 0; i < deleting.length; i++) {
          await deleteResourceById(deleting[i].id, props.token);
        }
      }
    } catch (error) {
      console.log("Error occured at handleResources", error);
    }
  }

  async function handleSave() {
    setSaving(true);
    try {
      if (changesMade.exam) {
        await saveExamDetails();
      }
      if (changesMade.resources) {
        await handleResources();
      }
      if (changesMade.questions) {
        await handleQuestions();
      }
      setChangesMade({ exam: false, resources: false, questions: false });
      setSaving(false);
      navigate("/");
    } catch (error) {
      console.log("Error occured at handleSave", error);
    }
  }

  async function handleQuestions() {
    const result = { adding: [], deleting: [], editing: [] };
    questions.forEach((item) => {
      if (item.add && !item.id) {
        item.exam_id = exam.id || localBackUp;
        delete item.add;
        result.adding.push(item);
      } else if (item.delete && item.id) {
        result.deleting.push(item.id);
      } else if (item.edit && item.id) {
        delete item.edit;
        result.editing.push(item);
      }
    });
    if (result.adding.length && (exam.id || localBackUp)) {
      try {
        await bulkAddQuestions(result.adding, props.token);
      } catch (error) {
        console.log("Error occured at handleQuestions Adding", error);
      }
    }
    if (result.deleting.length && (exam.id || localBackUp)) {
      try {
        await bulkDeleteQuestions(result.deleting.join(","), props.token);
      } catch (error) {
        console.log("Error occured at handleQuestions Deleting", error);
      }
    }
    if (result.editing.length && (exam.id || localBackUp)) {
      try {
        await bulkEditQuestions(exam.id, result.editing, props.token);
      } catch (error) {
        console.log("Error occured at handleQuestions Editing", error);
      }
    }
  }

  async function deleteExam() {
    if (deleting && examId !== "new") {
      try {
        await deleteUserExam(examId, props.token);
      } catch (error) {
        console.log("Error occured at deleteExam", error);
      }
    }
    navigate("/");
  }
  return (
    <div className="page" id="mod-exam-page">
      <NavBar isFlashHome={false} user={props.user} token={props.token} />

      <div className="page-box">
        <Card
          className="card-page"
          sx={{
            boxShadow: "var(--box-shadow)",
            borderRadius: "var(--borderRadius)",
          }}
        >
          <div className="header">
            <h1>{examId !== "new" ? "Edit Exam" : "New Exam"}</h1>
            {examId !== "new" && (
              <IconButton
                style={trashStyles}
                color="error"
                onClick={() => setDeleting(true)}
              >
                <Delete fontSize="inherit" color="error" />
              </IconButton>
            )}
          </div>
          <CardContent className="card-content">
            {deleting ? (
              <Alert
                type="error"
                severity="error"
                color="error"
                variant="filled"
                className="card-alert"
                sx={{
                  "& .MuiAlert-action": {
                    padding: 0,
                    gap: 1,
                    alignItems: "center",
                  },
                }}
                action={[
                  <Button
                    color="ghostBlack"
                    variant="contained"
                    disableElevation
                    key="cancel"
                    onClick={() => setDeleting(false)}
                  >
                    Cancel
                  </Button>,
                  <Button
                    color="ghostRed"
                    variant="contained"
                    disableElevation
                    key="delete"
                    onClick={() => deleteExam()}
                  >
                    Delete
                  </Button>,
                ]}
              >
                Are you sure you would like to delete this exam?
              </Alert>
            ) : (
              <Alert
                type="info"
                severity="info"
                color="primary"
                className="card-alert"
                variant="filled"
              >
                All changes made to the exam, resources and questions need to be
                saved below
              </Alert>
            )}

            <div className="rows">
              <div className="content-group">
                <h4 className="italic-subtitle">Exam Details</h4>
                <form
                  className="d-flex gap-3 flex-column"
                  style={{ width: "100%" }}
                >
                  <TextField
                    variant="outlined"
                    color="black"
                    label="Url Image"
                    value={exam ? exam.logo : ""}
                    fullWidth
                    onChange={(e) => {
                      setExam({ ...exam, logo: e.target.value });
                      setChangesMade({ ...changesMade, exam: true });
                    }}
                  />
                  <TextField
                    variant="outlined"
                    color="black"
                    label="Title"
                    inputProps={{ maxLength: 50 }}
                    error={!exam.name && changesMade.exam}
                    required
                    fullWidth
                    value={exam ? exam.name : ""}
                    onChange={(e) => {
                      setExam({ ...exam, name: e.target.value });
                      setChangesMade({ ...changesMade, exam: true });
                    }}
                  />
                  <TextField
                    variant="outlined"
                    color="black"
                    label="Subtitle"
                    inputProps={{ maxLength: 80 }}
                    error={!exam.description && changesMade.exam}
                    required
                    fullWidth
                    value={exam ? exam.description : ""}
                    onChange={(e) => {
                      setExam({ ...exam, description: e.target.value });
                      setChangesMade({ ...changesMade, exam: true });
                    }}
                  />
                </form>
              </div>
              <hr style={{ margin: 0 }} />
              <div className="content-group">
                <h4 className="italic-subtitle">
                  <div>Resources</div>
                  {visibleResources.length > 0 && !loading && (
                    <IconButton
                      size="large"
                      color="primary"
                      className="p-0"
                      disabled={resources.length === 3}
                      onClick={() => setShowResourceModal("active")}
                    >
                      <AddBoxRounded fontSize="large" />
                    </IconButton>
                  )}
                </h4>
                <div className="container-box resources ">
                  {visibleResources.length === 0 && !loading && (
                    <Button
                      fullWidth
                      onClick={() => setShowResourceModal("active")}
                    >
                      <div className="ghost-section">
                        <h6 className="mb-0">Add Resources</h6>

                        <AddBoxRounded color="primary" className="fab-icon" />
                      </div>
                    </Button>
                  )}
                  {loading && (
                    <ClipLoader
                      color={"#b80c09"}
                      className="loader"
                      loading={loading}
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  )}
                  {resources.length > 0 && (
                    <DisplayResources
                      resources={resources}
                      setResources={setResources}
                      changesMade={changesMade}
                      setChangesMade={setChangesMade}
                    />
                  )}
                </div>
              </div>
            </div>
            <hr />

            <div className="content-group pb-0">
              <h4 className="italic-subtitle">
                <div>Questions</div>
                {visibleQuestion.length > 0 && !loading && (
                  <IconButton
                    size="large"
                    color="primary"
                    className="p-0"
                    onClick={() => setShowQuestionModal("active")}
                  >
                    <AddBoxRounded fontSize="large" />
                  </IconButton>
                )}
              </h4>
              <div className="container-box questions">
                {visibleQuestion.length === 0 && !loading && (
                  <Button
                    fullWidth
                    onClick={() => setShowQuestionModal("active")}
                  >
                    <div className="ghost-section">
                      <h6 className="mb-0">Add Questions</h6>

                      <AddBoxRounded color="primary" className="fab-icon" />
                    </div>
                  </Button>
                )}
                {loading && (
                  <ClipLoader
                    color={`var(--red)`}
                    className="loader"
                    loading={loading}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
                {questions.length > 0 && (
                  <DisplayQuestions
                    questions={questions}
                    setQuestionId={setQuestionSelectedId}
                    setOpen={setShowQuestionModal}
                    setIndex={setIndex}
                  />
                )}
              </div>
            </div>
          </CardContent>
          <CardActions className="float-actions">
            <Button
              href={"/"}
              size="large"
              color="ghostBlack"
              variant="contained"
              disableElevation
              sx={{ width: "10rem" }}
            >
              cancel
            </Button>
            <Button
              size="large"
              color="black"
              variant="contained"
              disabled={(!exam.title && !exam.description) || saving}
              disableElevation
              sx={{ width: "10rem" }}
              onClick={handleSave}
            >
              {saving ? (
                <ClipLoader
                  color={"#fff"}
                  loading={saving}
                  size={26}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                "Save"
              )}
            </Button>
          </CardActions>
        </Card>
      </div>
      <ResourceModal
        examId={exam.id}
        open={showResourceModal}
        setOpen={setShowResourceModal}
        setResources={setResources}
        changesMade={changesMade}
        resources={resources}
        setChangesMade={setChangesMade}
      />
      <ModifyQuestion
        open={showQuestionModal}
        setOpen={setShowQuestionModal}
        questionId={questionSelectedId}
        setQuestions={setQuestions}
        questions={questions}
        setChangesMade={setChangesMade}
        changesMade={changesMade}
        questionIndex={index}
        setIndex={setIndex}
        token={props.token}
        setQuestionId={setQuestionSelectedId}
        examId={exam.id || localBackUp}
      />
    </div>
  );
};

export default ModExamPage;
