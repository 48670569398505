import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getAllCards from "../../endpoints/FlashCards/functions";
import PlayCards from "../../components/PlayContent/Cards/PlayCards";
import { Card, CardActions, CardContent, Button } from "@mui/material";
const PlayHome = (props) => {
  const location = useLocation();
  const filter = location.state.filter || null;
  const navigate = useNavigate();
  const [playingCards, setPlayingCards] = useState([]);
  const [index, setIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!filter || !props.token) {
      return navigate("/flashcards");
    }

    const fetchCards = async () => {
      try {
        let data = await getAllCards(props.token, filter);
        if (data.length === 0) {
          setShowAlert(true);
          return;
        }
        setPlayingCards(data);
        setTotal(data.length);
      } catch (error) {
        console.log("Error occured at PlayHome mount", error);
      }
    };
    fetchCards();
  }, []);

  if (!showAlert && filter && playingCards && playingCards.length > 0) {
    return (
      <PlayCards
        total={total}
        playingCards={playingCards}
        index={index}
        setIndex={setIndex}
        token={props.token}
      />
    );
  } else if (showAlert) {
    return (
      <div id="playing-mode">
        <Card sx={{ boxShadow: "var(--box-shadow)" }}>
          <div className="header">
            <h1>No Cards Found</h1>
          </div>
          <CardContent>
            Looks like you do not have cards for the selection you made. Click
            below to return to the home page.
          </CardContent>
          <CardActions>
            <Button
              onClick={() => navigate("/flashcards")}
              disableElevation
              color="black"
              variant="contained"
              sx={{ width: "8rem" }}
            >
              Go Home
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
};

export default PlayHome;
