import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import DashboardView from "../../components/DisplayItems/DashboardView";
import FlashTopBar from "../../components/Search/FlashTopBar";
import CardView from "../../components/DisplayItems/CardView";
import { useLocation, useNavigate } from "react-router-dom";
import getAllCards, {
  addCard,
  deleteCategory,
  getAllCategories,
  getAllDecks,
} from "../../endpoints/FlashCards/functions";
import Footer from "../../components/Custom/Footer";
import {
  Alert,
  Button,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import { AddRounded, PlayCircleFilledRounded } from "@mui/icons-material";
import AddCategory from "../../components/Modals/AddCategory";
import ModifyCard from "../../components/Modals/ModifyCard";
const FlashHome = (props) => {
  const navigate = useNavigate();
  const [decks, setDecks] = useState([]);
  const [deleteCat, setDeleteCat] = useState(null);
  const [categories, setCategories] = useState([]);
  const [mobileView, setmobileView] = useState(false);
  const [cards, setCards] = useState([]);
  const [modalOpen, setModalOpen] = useState("");
  const [setCardModal, setCardModalOpen] = useState("");
  const [speedDial, setSpeedDial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pinned, setPinned] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const actions = [
    {
      icon: <AddRounded />,
      name: "Add Category",
      action: () => setModalOpen("active"),
      show: true,
    },
    {
      icon: <AddRounded />,
      name: "Add Deck",
      action: () => navigate("/flashcards/deck/new"),
      show: categories.length !== 0,
    },
    {
      icon: <AddRounded />,
      name: "Add Card",
      action: () => setCardModalOpen("active"),
      show: decks.length !== 0,
    },
  ];

  const [filter, setFilter] = useState({
    category: searchParams.get("category") || "",
    deck: searchParams.get("deck") || "",
    shuffle: searchParams.get("shuffle") === "true",
    study: searchParams.get("study") === "true",
    search: searchParams.get("search") || "",
  });
  async function getDecks() {
    try {
      let result = await getAllDecks(props.token);
      setDecks(result);
    } catch (error) {
      console.log("Error at getDecks", error);
    }
  }

  async function handleDeleteCategory(id) {
    try {
      await deleteCategory(id, props.token);
      setDeleteCat(null);
      await getCategories();
      await getDecks();
    } catch (error) {
      console.log("Error occured at handleDeleteCategory", error);
    }
  }

  async function getCategories() {
    try {
      let result = await getAllCategories(props.token);
      setCategories(result);
    } catch (error) {
      console.log("Error at getCategories", error);
    }
  }
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await getCategories();
      await getDecks();
      setLoading(false);
    }
    fetchData();
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (deleteCat) {
      const pageElement = document.getElementById("flash-box");
      pageElement.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [deleteCat]);

  useEffect(() => {
    // Parse the query parameters from the URL
    const url = new URL(window.location.href);
    if (url.pathname === "/flashcards") {
      const deck = searchParams.get("deck") || "";
      const category = searchParams.get("category") || "";
      const study = searchParams.get("study") === "true";
      const shuffle = searchParams.get("shuffle") === "true";
      const search = searchParams.get("search") || "";

      if (
        deck !== filter.deck ||
        category !== filter.category ||
        study !== filter.study ||
        shuffle !== filter.shuffle ||
        search !== filter.search
      ) {
        setFilter({
          deck,
          category,
          shuffle,
          study,
          search,
        });
      }
      if (
        deck === "" &&
        category === "" &&
        !study &&
        !shuffle &&
        search === ""
      ) {
        setCards([]);
      }
    }
  }, [location.search]);

  function handleResize() {
    if (window.innerWidth <= 950) {
      setmobileView(true);
      setPinned(false);
    } else if (window.innerWidth <= 1095) {
      setPinned(false);
    } else {
      setmobileView(false);
    }
  }

  async function getCards(filters) {
    try {
      let data = await getAllCards(props.token, filters);
      setCards(data);
    } catch (error) {
      console.log("Error at getCards", error);
    }
  }

  function playShuffle() {
    const updatedFilter = filter;
    updatedFilter.shuffle = true;
    navigate("/flashcards/play", { state: { filter: updatedFilter } });
  }

  async function saveCard(card) {
    try {
      await addCard(card, props.token);
      if (filter.category !== "" || filter.deck !== "" || filter.study) {
        await getCards(filter);
      }
    } catch (error) {
      console.log("Error at saveCard", error);
    }
  }
  return (
    <div className="page" id="flashcards">
      <NavBar
        isFlashHome={true}
        filter={filter}
        user={props.user}
        token={props.token || localStorage.getItem("token")}
      />
      <div className="page-box" id="flash-box">
        <div className="flash-top">
          <FlashTopBar
            decks={decks}
            cards={cards}
            categories={categories}
            pinned={pinned}
            setPinned={setPinned}
            filter={filter}
            setFilter={setFilter}
            token={props.token || localStorage.getItem("token")}
            getCards={getCards}
          />
        </div>

        <div className={pinned ? "pinned" : ""}>
          {deleteCat && (
            <Alert
              type="error"
              severity="error"
              color="error"
              variant="filled"
              className="card-alert flash"
              sx={{
                "& .MuiAlert-action": {
                  padding: 0,
                  gap: 1,
                  alignItems: "center",
                },
                "& .MuiAlert-message": {
                  paddingRight: "1rem",
                },
                marginRight: pinned ? "1rem" : "0",
              }}
              action={[
                <Button
                  color="ghostBlack"
                  variant="contained"
                  disableElevation
                  key="cancel"
                  onClick={() => setDeleteCat(null)}
                >
                  Cancel
                </Button>,
                <Button
                  color="ghostRed"
                  variant="contained"
                  disableElevation
                  key="delete"
                  onClick={(e) => handleDeleteCategory(deleteCat.id)}
                >
                  Delete
                </Button>,
              ]}
            >
              Are you sure you would like to delete {deleteCat.name}? This will
              delete all decks and cards associated with this category.
            </Alert>
          )}
          {filter.category !== "" ||
          filter.deck !== "" ||
          filter.study ||
          filter.search !== "" ? (
            <CardView
              cards={cards}
              token={props.token || localStorage.getItem("token")}
              getCards={getCards}
              categories={categories}
              decks={decks}
              filter={filter}
            />
          ) : (
            <DashboardView
              categories={categories}
              decks={decks}
              loading={loading}
              setDeleteCat={setDeleteCat}
              token={props.token || localStorage.getItem("token")}
              getDecks={getDecks}
              getCategories={getCategories}
              filter={filter}
              setFilter={setFilter}
            />
          )}
          <Footer />
          {mobileView && (
            <IconButton
              color="error"
              onClick={playShuffle}
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                margin: 1,
                zIndex: 100,
                width: "4rem",
                height: "4rem",
                backgroundColor: "white",
                boxShadow: "var(--box-shadow)",
              }}
            >
              <PlayCircleFilledRounded sx={{ fontSize: "5rem" }} />
            </IconButton>
          )}
        </div>
      </div>
      <SpeedDial
        ariaLabel="SpeedDial add actions"
        sx={{
          position: "fixed",
          bottom: 2,
          right: 2,
          zIndex: 100,
          p: 1,
          ".MuiSpeedDial-fab": {
            boxShadow: "none",
          },
        }}
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDial(false)}
        open={speedDial}
        onOpen={() => setSpeedDial(true)}
      >
        {actions.map(
          (action) =>
            action.show && (
              <SpeedDialAction
                sx={{
                  ".MuiSpeedDialAction-staticTooltipLabel": {
                    width: "max-content",
                    boxShadow: "var(--box-shadow)",
                    borderRadius: "var(--borderRadius)",
                  },
                  ".MuiSpeedDialAction-fabClosed": {
                    boxShadow: "var(--box-shadow)",
                  },
                }}
                color="ghostBlue"
                disabled={action.disabled}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={() => action.action()}
              />
            )
        )}
      </SpeedDial>
      <AddCategory
        token={props.token || localStorage.getItem("token")}
        getCategories={getCategories}
        openModal={modalOpen}
        setOpenModal={setModalOpen}
      />

      <ModifyCard
        show={setCardModal}
        setShow={setCardModalOpen}
        decks={decks}
        index={null}
        deck={undefined}
        setIndex={() => {}}
        cards={cards}
        handleSave={saveCard}
      />
    </div>
  );
};

export default FlashHome;
