import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";

// pages imported
import RegisterPage from "./pages/Authenication/RegisterPage";
import LoginPage from "./pages/Authenication/LoginPage";
import ExamHome from "./pages/Assessment/ExamHome";
import useAuth from "./hooks/useAuth";
import StartExamPage from "./pages/Assessment/StartExamPage";
import FlashHome from "./pages/FlashCard/FlashHome";
import ForgotPage from "./pages/Authenication/ForgotPage";
import PlayHome from "./pages/FlashCard/PlayHome";
import ModExamPage from "./pages/Assessment/ModExamPage";
import ModDeckPage from "./pages/FlashCard/ModDeckPage";

function App() {
  const [user, token] = useAuth();

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <ExamHome user={user} token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path="/flashcards"
          element={
            <PrivateRoute>
              <FlashHome user={user} token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path="/flashcards/play"
          element={
            <PrivateRoute>
              <PlayHome user={user} token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path="/exam/:examId"
          element={
            <PrivateRoute>
              <ModExamPage user={user} token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path="/flashcards/deck/:deckId"
          element={
            <PrivateRoute>
              <ModDeckPage user={user} token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path="/:examName"
          element={<StartExamPage user={user} token={token} />}
        />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPage />} />
      </Routes>
    </div>
  );
}

export default App;
