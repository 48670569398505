import React, { useState } from "react";
import "./styles/DashboardView.css";
import { ClipLoader } from "react-spinners";
import { updateCategory } from "../../endpoints/FlashCards/functions";
import { Button, IconButton, PopoverPaper } from "@mui/material";
import { CloseRounded, MoreVertRounded } from "@mui/icons-material";
import DeckCard from "./DeckCard";
import Title from "../Custom/Title";
import { Link, useNavigate } from "react-router-dom";
const DashboardView = (props) => {
  const navigate = useNavigate();
  const [editableItemId, setEditableItemId] = useState(null);

  async function handleUpdateCategory(id, newName) {
    try {
      await updateCategory(id, props.token, newName);
      await props.getCategories();
    } catch (error) {
      console.log("Error occured at handleUpdateCategory", error);
    }
  }

  function playCategory(categoryName) {
    let newFilter = { ...props.filter };
    newFilter.category = categoryName;
    newFilter.shuffle = true;
    navigate("/flashcards/play", { state: { filter: newFilter } });
  }
  const CardSection = ({ items, category }) => {
    const [showCatMenu, setShowCatMenu] = useState(false);
    return (
      <div className="position-relative mb-5">
        <h2 className="heading text-start px-4 d-flex justify-content-between gap-2 align-items-center">
          <Title
            id={category.id}
            text={category.name}
            onEdit={handleUpdateCategory}
            isEditing={editableItemId === category.id}
            setEditableItemId={setEditableItemId}
          ></Title>
          <IconButton onClick={() => setShowCatMenu(!showCatMenu)}>
            {showCatMenu ? <CloseRounded /> : <MoreVertRounded />}
          </IconButton>
        </h2>
        <PopoverPaper
          square={false}
          sx={{
            position: "absolute",
            top: 45,
            right: 30,
            boxShadow: "var(--box-shadow)",
            zIndex: 10001,
          }}
          className="popover-menu"
          hidden={!showCatMenu}
          open={showCatMenu}
          onClose={(e) => setShowCatMenu(false)}
          onMouseLeave={(e) => setTimeout(() => setShowCatMenu(false), 500)}
        >
          <ul className="menu">
            <li>
              <button
                className="menu-link"
                onClick={() => playCategory(category.name)}
                disabled={category.flashcard_count === 0}
              >
                Play Category
              </button>
            </li>
            <li>
              <div
                className="menu-link"
                onClick={() => setEditableItemId(category.id)}
              >
                Edit Category
              </div>
            </li>
            <li>
              <Link
                className="menu-link"
                to="/flashcards/deck/new"
                state={{ category: category.id }}
              >
                Add Deck
              </Link>
            </li>
            <li className="no-border p-0">
              <Button
                color="ghostRed"
                variant="contained"
                className="px-4"
                sx={{
                  borderRadius: "0 0 var(--borderRadius) var(--borderRadius)",
                }}
                onClick={(e) => {
                  props.setDeleteCat(category);
                }}
              >
                Delete Category
              </Button>
            </li>
          </ul>
        </PopoverPaper>
        <div className="category-decks">
          {!items.length && (
            <div id="no-items">
              <h6 className="mb-0 no-cap"> No decks found</h6>
            </div>
          )}
          {items &&
            items.map((item) => (
              <DeckCard
                key={`category-${category.id}-deck-${item.id}`}
                item={item}
                category={category}
                filter={props.filter}
                setFilter={props.setFilter}
              />
            ))}
        </div>
      </div>
    );
  };

  if (props.loading) {
    return (
      <div className="around-no-exams">
        <div id="flash-loading" className="exams-container">
          <ClipLoader
            color={"#b80c09"}
            loading={props.loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    );
  } else if (
    !props.loading &&
    props.categories &&
    props.categories.length > 0
  ) {
    return props.categories.map((category) => {
      return (
        <CardSection
          key={`category-display-${category.id}`}
          items={category.decks}
          category={category}
        />
      );
    });
  } else if (
    !props.loading &&
    props.categories &&
    props.categories.length === 0
  ) {
    return (
      <div className="around-no-exams">
        <div id="no-exams" className="exams-container">
          <h1>no items found</h1>
        </div>
      </div>
    );
  }
};

export default DashboardView;
