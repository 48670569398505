import React, { useEffect, useState } from "react";
import "./styles/Modals.css";
import Teleport from "../Custom/Teleport";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import TextAreaField from "../Custom/TextAreaField";
import { getQuestionById } from "../../endpoints/Assessment/functions";
import { trashStyles } from "../../context/customStyles";
const ModifyQuestion = (props) => {
  const [error, setError] = useState("");
  const [question, setQuestion] = useState({
    exam_id: props.examId,
    question: "",
    image: null,
  });
  const [choices, setChoices] = useState([
    { choice: "", position: 1, correct: false },
    { choice: "", position: 2, correct: false },
    { choice: "", position: 3, correct: false },
    { choice: "", position: 4, correct: false },
  ]);
  function toggleChecks(position, value) {
    setChoices(
      choices.map((item) => {
        if (item.position === position) {
          return { ...item, correct: value };
        }

        return { ...item, correct: value ? false : item.correct };
      })
    );
    props.setChangesMade({ ...props.changesMade, questions: true });
  }

  useEffect(() => {
    if (
      props.questionId &&
      props.open === "active" &&
      props.questionIndex === null
    ) {
      const fetchData = async () => {
        const res = await getQuestionById(props.questionId, props.token);
        setQuestion({
          id: res.id,
          exam_id: res.exam.id,
          question: res.question,
          image: null,
        });
        setChoices(res.choices);
      };
      fetchData();
    } else if (props.questionIndex !== null && props.open === "active") {
      setQuestion(props.questions[props.questionIndex]);
      setChoices(props.questions[props.questionIndex].choices || []);
    } else {
      setQuestion({
        exam_id: props.examId,
        question: "",
        image: null,
      });
      setChoices([
        { choice: "", position: 1, correct: false },
        { choice: "", position: 2, correct: false },
        { choice: "", position: 3, correct: false },
        { choice: "", position: 4, correct: false },
      ]);
    }
  }, [props.open, props.questionId]);

  useEffect(() => {
    if (
      error &&
      question.question &&
      choices.find((choice) => choice.correct) &&
      !choices.some((choice) => !choice.choice) &&
      choices.length === new Set(choices.map((choice) => choice.choice)).size
    ) {
      setError("");
    }
  }, [error, question, choices]);

  function handleClose() {
    props.setOpen("");
    props.setQuestionId(null);
    props.setIndex(null);
    setError("");
  }

  function validated() {
    let errorMessage = "";
    if (!question.question) {
      errorMessage = "A question is required. ";
    }
    if (!choices.find((choice) => choice.correct)) {
      errorMessage += "One correct choice needs to be selected. ";
    }
    if (choices.some((choice) => !choice.choice)) {
      errorMessage += "All choices are required.";
    }
    if (
      choices.length !== new Set(choices.map((choice) => choice.choice)).size
    ) {
      errorMessage += "Choices must be unique.";
    }
    if (errorMessage) {
      setError(errorMessage);
      return false;
    }

    setError("");
    return true;
  }
  function handleAddQuestion() {
    if (validated()) {
      if (props.questionIndex === null) {
        props.setQuestions((prevQuestions) => {
          const newQuestions = [...prevQuestions];
          newQuestions.push({ ...question, choices, add: true });
          return newQuestions;
        });
      } else {
        props.setQuestions((prevQuestions) => {
          const newQuestions = [...prevQuestions];
          newQuestions[props.questionIndex] = {
            ...question,
            exam_id: props.examId,
            choices,
            add: true,
          };
          return newQuestions;
        });
      }
      props.setChangesMade({ ...props.changesMade, questions: true });
      handleClose();
    }
  }

  function handleDeleteQuestion() {
    if (props.questionId) {
      props.setQuestions((prevQuestions) => {
        const newQuestions = [...prevQuestions];
        newQuestions[props.questionIndex].delete = true;
        return newQuestions;
      });
    } else {
      props.setQuestions((prevQuestions) => {
        const newQuestions = [...prevQuestions];
        newQuestions.splice(props.questionIndex, 1);
        return newQuestions;
      });
    }
    props.setChangesMade({ ...props.changesMade, questions: true });
    handleClose();
  }

  function handleEditQuestion() {
    if (validated()) {
      props.setQuestions((prevQuestions) => {
        const newQuestions = [...prevQuestions];
        newQuestions[props.questionIndex] = {
          ...question,
          question: question.question,
          choices,
          edit: true,
        };
        return newQuestions;
      });
      props.setChangesMade({ ...props.changesMade, questions: true });
      handleClose();
    }
  }

  function setNewQuestionValue(value) {
    setQuestion({
      ...question,
      question: value,
    });
  }
  return (
    <Teleport>
      <div className={props.open} id="choice-modal">
        <div className="header">
          <h1>
            {props.questionIndex !== null ? "Edit Question" : "Add Question"}
          </h1>
          {props.questionIndex !== null && (
            <IconButton
              style={trashStyles}
              color="error"
              onClick={handleDeleteQuestion}
            >
              <Delete fontSize="inherit" color="error" />
            </IconButton>
          )}
        </div>
        <div className="modal-body">
          {!!error && (
            <Alert severity="error" type="error" variant="filled">
              {error}
            </Alert>
          )}
          <div className="w-100">
            <h4 className="italic-subtitle">
              <div>Question Details</div>
            </h4>
            <TextAreaField
              label="Question *"
              value={question.question}
              data={question}
              error={!!error && !question.question}
              setQuestion={setNewQuestionValue}
            />
          </div>
          <div className="w-100">
            <h4 className="italic-subtitle">Choices</h4>
            <div className="choices">
              {choices.map((choice, index) => {
                return (
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "2px" }}
                    key={`choice-${choice.position}`}
                  >
                    <TextField
                      fullWidth
                      required
                      color="black"
                      variant="outlined"
                      label={`Choice ${choice.position}`}
                      className="choice"
                      value={choice.choice}
                      error={!!error && !choice.choice}
                      inputProps={{ maxLength: 126 }}
                      onChange={(e) => {
                        setChoices((prevChoices) => {
                          const newChoices = [...prevChoices];
                          newChoices[index].choice = e.target.value;
                          return newChoices;
                        });
                      }}
                    />
                    <Checkbox
                      checked={choice.correct}
                      size="small"
                      title="Correct Answer"
                      color={
                        !!error && !choices.find((choice) => choice.correct)
                          ? "error"
                          : "black"
                      }
                      onChange={(e) =>
                        toggleChecks(choice.position, e.target.checked)
                      }
                    />
                  </Box>
                );
              })}
            </div>
            <div className="float-actions gap-2 d-flex justify-content-center pt-3">
              <Button
                onClick={() => handleClose()}
                color="ghostBlack"
                variant="contained"
                sx={{ maxWidth: "10rem", width: "100%" }}
                disableElevation
              >
                Cancel
              </Button>
              <Button
                color="black"
                className="px-14"
                variant="contained"
                disableElevation
                sx={{ maxWidth: "10rem", width: "100%" }}
                onClick={
                  props.questionId && question.id
                    ? handleEditQuestion
                    : handleAddQuestion
                }
              >
                {props.questionIndex !== null ? "Save" : "Add"}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={props.open} id="choice-overlay"></div>
    </Teleport>
  );
};
export default ModifyQuestion;
