import React, { useState } from "react";
import "./styles/CardView.css";
import Cards from "./Cards";
import ModifyCard from "../Modals/ModifyCard";
import { editCard, deleteCard } from "../../endpoints/FlashCards/functions";
const CardView = (props) => {
  const [cardModal, setCardModal] = useState("");
  const [index, setIndex] = useState(null);
  const renderNoCards = () => (
    <div id="no-cards-view">
      <div className="no-cards">{renderAddCard()}</div>
    </div>
  );

  async function updateCard(cards) {
    try {
      await editCard(cards[0].id, props.token, cards[0]);
      await props.getCards(props.filter);
      setCardModal("");
      setIndex(null);
    } catch (error) {
      console.log("Error occured at updateCard", error);
    }
  }

  async function removeCard() {
    try {
      await deleteCard(props.cards[index].id, props.token);
      await props.getCards(props.filter);
      setCardModal("");
      setIndex(null);
    } catch (error) {
      console.log("Error occured at removeCard", error);
    }
  }
  const renderCards = () => (
    <div id="card-view">
      {props.cards &&
        props.cards.map((card, index) => (
          <Cards
            card={card}
            key={card.id}
            index={index}
            setIndex={setIndex}
            token={props.token}
            categories={props.categories}
            decks={props.decks}
            getCards={props.getCards}
            filter={props.filter}
            showEditModal={setCardModal}
          />
        ))}
      <ModifyCard
        show={cardModal}
        setShow={setCardModal}
        decks={props.decks}
        index={index}
        deck={undefined}
        setIndex={setIndex}
        cards={props.cards}
        handleSave={updateCard}
        handleRemove={removeCard}
      />
    </div>
  );

  const renderAddCard = () =>
    props.cards.length === 0 && <h1>NO CARDS FOUND</h1>;

  if (props.cards.length > 0) {
    return renderCards();
  } else return renderNoCards();
};

export default CardView;
