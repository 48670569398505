import { RemoveCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import "./styles/EditExamView.css";
import React from "react";
import { trashStyles } from "../../context/customStyles";
const DisplayResources = (props) => {
  return (
    <ul className="list-view">
      {props.resources.map((item, index) => {
        if (item.delete) {
          return null;
        }
        return (
          <li key={`resource-${index}`} className="list-item">
            <a
              href={
                item.localLink
                  ? item.localLink
                  : `https://rezin.pythonanywhere.com${item.file}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
            <div>
              <IconButton
                style={trashStyles}
                color="error"
                onClick={() => {
                  props.setResources((prevValue) => {
                    return prevValue.map((resource, i) => {
                      if (i === index) {
                        return { ...resource, delete: true };
                      }
                      return resource;
                    });
                  });
                  props.setChangesMade({
                    ...props.changesMade,
                    resources: true,
                  });
                }}
              >
                <RemoveCircle fontSize="inherit" color="error" />
              </IconButton>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default DisplayResources;
