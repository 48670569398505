import React, { useState } from "react";
import "./styles/Modals.css";
import Teleport from "../Custom/Teleport";
import { FilePresent, FileUpload } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { VisuallyHiddenInput } from "../Custom/InputFile";
const ResourceModal = (props) => {
  const [newResource, setNewResource] = useState({
    name: "",
    file: null,
    localLink: null,
  });

  function toggleResourceModal() {
    if (props.open === "active") {
      props.setOpen("");
      setNewResource({
        ...newResource,
        name: "",
        file: null,
        localLink: null,
      });
    } else {
      props.setOpen("active");
    }
  }
  return (
    <Teleport>
      <div className={props.open} id="resource-modal">
        <div className="header">
          <h1>Add Resource</h1>
          <hr />
          <button onClick={() => toggleResourceModal()} className="close-modal">
            &times;
          </button>
        </div>
        <div className="modal-body">
          <>
            {" "}
            <TextField
              name="resource name"
              sx={{ minWidth: 180 }}
              color="black"
              label="Resource Name"
              className="input-name"
              onChange={(e) => {
                setNewResource({
                  ...newResource,
                  name: e.target.value,
                });
                props.setChangesMade({ ...props.changesMade, resources: true });
              }}
              value={newResource.name}
              inputProps={{ maxLength: 20 }}
            />
            <Button
              disableElevation
              className="input-file"
              component="label"
              sx={{ minWidth: "5rem", height: "3.5rem" }}
              variant="contained"
              htmlFor="file-input-resource"
              color="primary"
            >
              {newResource.file ? <FilePresent /> : <FileUpload />}
            </Button>
            <VisuallyHiddenInput
              type="file"
              id="file-input-resource"
              onChange={(event) =>
                setNewResource({
                  ...newResource,
                  file: event.target.files[0],
                  localLink: URL.createObjectURL(event.target.files[0]),
                })
              }
            />
            <Button
              onClick={() => {
                props.setResources([...props.resources, newResource]);
                toggleResourceModal();
              }}
              sx={{ minWidth: "8rem", height: "3.5rem" }}
              variant="contained"
              disableElevation
              color="black"
            >
              Add
            </Button>
          </>
        </div>
      </div>
      <div className={props.open} id="resource-overlay"></div>
    </Teleport>
  );
};
export default ResourceModal;
