import React, { useEffect, useState } from "react";
import "./styles/FlashTopBar.css";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  PopoverPaper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Close,
  FilterListRounded,
  PushPin,
  PushPinOutlined,
  Search,
} from "@mui/icons-material";
import { filterStyles } from "../../context/customStyles";
import Teleport from "../Custom/Teleport";

const FlashTopBar = (props) => {
  const navigate = useNavigate();
  const [mobileView, setmobileView] = useState(false);
  const [dynamicCss, setDynamicCss] = useState(80);
  const [showFilters, setShowFilters] = useState(false);
  const [localSearch, setLocalSearch] = useState(props.filter.search);
  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.pathname === "/flashcards") {
      if (
        props.filter.category === "" &&
        props.filter.deck === "" &&
        props.filter.study === false &&
        props.filter.search === ""
      )
        return navigate("/flashcards");
      props.getCards(props.filter);
      let route = url.pathname;
      let params = [];
      if (props.filter.deck !== "") {
        params.push(`deck=${props.filter.deck}`);
      }
      if (props.filter.category !== "")
        params.push(`category=${props.filter.category}`);
      if (props.filter.study) params.push(`study=${props.filter.study}`);
      if (props.filter.shuffle) params.push(`shuffle=${props.filter.shuffle}`);
      if (localSearch) params.push(`search=${localSearch}`);
      if (params.length > 0) route += `?${params.join("&")}`;
      navigate(route);
    }
  }, [props.filter]);

  function playShuffle() {
    const filter = props.filter;
    filter.shuffle = true;
    navigate("/flashcards/play", { state: { filter: filter } });
  }

  function handleResize() {
    if (window.innerWidth <= 950) {
      setDynamicCss(125);
      setmobileView(true);
      props.setPinned(false);
    } else {
      setDynamicCss(80);
      setmobileView(false);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleCategoryChange(e) {
    // if the category is already selected, deselect it
    if (props.filter.category === e.target.value) {
      props.setFilter({ ...props.filter, category: "" });
    } else props.setFilter({ ...props.filter, category: e.target.value });
  }

  function handleDeckChange(e) {
    let newDecks = props.filter.deck !== "" ? props.filter.deck.split(",") : [];
    if (newDecks.includes(e.target.value)) {
      newDecks = newDecks.filter((deck) => deck !== e.target.value);
    } else {
      newDecks.push(e.target.value);
    }
    props.setFilter({ ...props.filter, deck: newDecks.join(",") });
  }
  return (
    <div className="filter-container">
      <div className="search-bar">
        {" "}
        <form
          style={{ width: "inherit" }}
          onClick={(e) => {
            e.preventDefault();
            props.setFilter({ ...props.filter, search: e.target.value });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.setFilter({ ...props.filter, search: e.target.value });
            }
          }}
        >
          <div className="search">
            <input
              type="text"
              id="flash-search-bar"
              placeholder="Search Cards..."
              autoComplete="off"
              list="autocompleteOff"
              value={localSearch}
              onChange={(e) => setLocalSearch(e.target.value)}
            />
            <div className="d-flex justify-center">
              {props.filter.search !== "" ? (
                <IconButton
                  onClick={() => {
                    setLocalSearch("");
                    props.setFilter({ ...props.filter, search: "" });
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              ) : (
                <div className="spacer"></div>
              )}
              <IconButton type="submit" disabled={localSearch === ""}>
                <Search fontSize="small" />
              </IconButton>
            </div>
          </div>
        </form>
      </div>
      <div className="filter-ctas">
        {!mobileView && (
          <Button
            color="error"
            variant="contained"
            disableElevation
            className="px-5"
            onClick={(e) => playShuffle(e)}
          >
            Play
          </Button>
        )}
        <IconButton
          style={filterStyles}
          disabled={
            (props.categories &&
              props.categories.length === 0 &&
              props.decks &&
              props.decks.length === 0) ||
            props.pinned
          }
          onClick={() => setShowFilters(!showFilters)}
        >
          <FilterListRounded color="black" />
        </IconButton>
      </div>
      <Teleport>
        <PopoverPaper
          square={false}
          sx={{
            position: "absolute",
            zIndex: 10000000,
            top: dynamicCss,
            right: 23,
            boxShadow: "var(--box-shadow)",
          }}
          className="popover-menu"
          hidden={!showFilters}
          open={showFilters}
          onClose={() => setShowFilters(false)}
          onMouseLeave={() =>
            !props.pinned ? setTimeout(() => setShowFilters(false), 800) : null
          }
        >
          <div className="filter-menu">
            <div className="header gap-4">
              <h6 className="mb-0">Show Cards</h6>
              <hr />
              <IconButton
                className="pin-modal"
                size="small"
                disabled={mobileView}
                onClick={() => props.setPinned(!props.pinned)}
              >
                {props.pinned ? <PushPin /> : <PushPinOutlined />}
              </IconButton>
            </div>
            <div className="filter-options">
              {props.categories && props.categories.length > 0 && (
                <div className="filter-group">
                  <FormLabel className="filter-subtitle">By Category</FormLabel>
                  <RadioGroup
                    className="filter-content"
                    aria-labelledby="controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={props.filter.category}
                    onChange={handleCategoryChange}
                  >
                    {props.categories.map((category) => {
                      return (
                        <FormControlLabel
                          className="m-0 filter-radio"
                          key={category.id}
                          label={category.name}
                          value={category.name}
                          control={
                            <Radio
                              color="black"
                              size="small"
                              onClick={handleCategoryChange}
                            />
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                </div>
              )}
              {props.decks && props.decks.length > 0 && (
                <>
                  <div className="filter-group">
                    <FormLabel className="filter-subtitle">By Deck</FormLabel>

                    <FormGroup
                      className="filter-content"
                      aria-labelledby="controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={props.filter.deck}
                      onChange={handleDeckChange}
                    >
                      {props.decks.map((deck) => {
                        return (
                          <FormControlLabel
                            className="m-0"
                            key={deck.id}
                            label={deck.name}
                            value={deck.name}
                            control={
                              <Checkbox
                                color="black"
                                size="small"
                                checked={props.filter.deck.includes(deck.name)}
                              />
                            }
                          />
                        );
                      })}
                    </FormGroup>
                  </div>
                  <div className="filter-group">
                    <FormLabel className="filter-subtitle">
                      By Review Needed
                    </FormLabel>
                    <FormGroup
                      className="filter-content"
                      aria-labelledby="controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={props.filter.study}
                      onChange={(e) =>
                        props.setFilter({
                          ...props.filter,
                          study: e.target.checked,
                        })
                      }
                    >
                      <FormControlLabel
                        className="m-0"
                        label="Review Cards"
                        control={<Checkbox color="black" size="small" />}
                      />
                    </FormGroup>
                  </div>
                </>
              )}
            </div>
          </div>
        </PopoverPaper>
      </Teleport>
    </div>
  );
};

export default FlashTopBar;
