import { CloseRounded, MoreVertRounded } from "@mui/icons-material";
import { IconButton, PopoverPaper, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const DeckCard = (props) => {
  const { item, category } = props;
  const navigate = useNavigate();
  const [showDeckMenu, setShowDeckMenu] = useState(false);

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowDeckMenu(false);
  };

  const redirectToPlay = (deckName) => {
    let newFilter = { ...props.filter };
    newFilter.deck = deckName;
    newFilter.shuffle = true;
    navigate("/flashcards/play", { state: { filter: newFilter } });
  };

  return (
    <div
      key={`category-${category.id}-deck-${item.id}`}
      className="deck-card-container"
    >
      <Tooltip
        title={
          item.flashcard_count === 0 ? "Please add cards to this deck." : ""
        }
        placement="bottom"
        arrow
      >
        <div
          className="deck-card"
          onClick={() => {
            if (!showDeckMenu) {
              if (item.flashcard_count > 0) redirectToPlay(item.name);
            }
          }}
        >
          <IconButton
            className="more"
            onClick={(e) => {
              if (item.flashcard_count > 0) e.stopPropagation();
              e.preventDefault();
              setShowDeckMenu(!showDeckMenu);
            }}
          >
            {showDeckMenu ? <CloseRounded /> : <MoreVertRounded />}
          </IconButton>
          <PopoverPaper
            square={false}
            sx={{
              position: "absolute",
              top: 30,
              right: 17,
              boxShadow: "var(--box-shadow)",
            }}
            className="popover-menu"
            hidden={!showDeckMenu}
            open={showDeckMenu}
            onClose={(e) => handleClose(e)}
            onMouseLeave={(e) => setTimeout(() => handleClose(e), 500)}
          >
            <ul className="menu">
              <li>
                <div
                  className="menu-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(`/flashcards/deck/${item.id}`);
                  }}
                >
                  Edit Deck
                </div>
              </li>
              <li className="no-border">
                <button
                  className="menu-link"
                  onClick={() => redirectToPlay(item.name)}
                  disabled={item.flashcard_count === 0}
                >
                  Play
                </button>
              </li>
            </ul>
          </PopoverPaper>
          <h6 style={{ marginBottom: 0 }}>{item.name}</h6>
        </div>
      </Tooltip>
    </div>
  );
};

export default DeckCard;
