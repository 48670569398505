import * as React from "react";
import Chip from "@mui/material/Chip";
import { VisuallyHiddenInput } from "./InputFile";
import { Button, IconButton } from "@mui/material";
import { Upload } from "@mui/icons-material";

const FileField = (props) => {
  const handleFileChange = (event) => {
    props.setSelectedFile(event.target.files[0]);
  };

  return (
    <>
      <Button
        fullWidth
        color="black"
        component="label"
        htmlFor="file-input"
        sx={{
          border: "1px dotted",
          backgroundColor: "var(--listRow)",
          height: "3.5rem",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 1rem",
        }}
      >
        {" "}
        {props.selectedFile ? (
          <Chip
            size="small"
            label={props.selectedFile.name}
            color="secondary"
            sx={{ mr: 1, zIndex: 1, borderRadius: "var(--borderRadius)" }}
          />
        ) : (
          <div>Upload File</div>
        )}
        {props.selectedFile && (
          <IconButton type="submit">
            <Upload color="black" />
          </IconButton>
        )}
      </Button>

      <VisuallyHiddenInput
        type="file"
        id="file-input"
        onChange={handleFileChange}
        accept=".csv"
      />
    </>
  );
};

export default FileField;
