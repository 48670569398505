import { EditRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import "./styles/EditExamView.css";
import React from "react";
import { pencilStyles } from "../../context/customStyles";
const DisplayCards = (props) => {
  function handleEditClick(index) {
    props.setIndex(index);
    props.setOpen("active");
  }
  return (
    <>
      <ul className="list-view">
        {props.cards.map((item, index) => {
          if (item.delete) {
            return null;
          }
          return (
            <li key={`cards-${index}`} className="list-item">
              <div className="ellipsis">{item.question}</div>
              <div>
                <IconButton
                  style={pencilStyles}
                  color="secondary"
                  onClick={() => handleEditClick(index)}
                >
                  <EditRounded fontSize="inherit" color="secondary" />
                </IconButton>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default DisplayCards;
