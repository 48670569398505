import React, { useState } from "react";
import "./styles/CardView.css";
import { toggleStudy } from "../../endpoints/FlashCards/functions";
import { IconButton, PopoverPaper } from "@mui/material";
import {
  CloseRounded,
  LocalLibrary,
  MoreVertRounded,
} from "@mui/icons-material";
const Cards = (props) => {
  const [flip, setFlip] = useState(false);
  const [showCardMenu, setShowCardMenu] = useState(false);
  async function handleToggleStudy(card) {
    const body = {
      deck_id: card.deck.id,
      question: card.question,
      answer: card.answer,
      hint: card.hint,
      in_study_pile: !card.in_study_pile,
    };
    try {
      await toggleStudy(card.id, body, props.token);
      await props.getCards(props.filter);
    } catch (error) {
      console.log("Error occured at handleToggleStudy", error);
    }
  }

  function handleClose(e) {
    e.stopPropagation();
    e.preventDefault();
    setShowCardMenu(false);
  }
  return (
    <div className="card-container">
      <div className="flash-card" onClick={() => setFlip(!flip)}>
        <IconButton
          className="more"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowCardMenu(!showCardMenu);
          }}
        >
          {showCardMenu ? <CloseRounded /> : <MoreVertRounded />}
        </IconButton>
        <PopoverPaper
          square={false}
          sx={{
            position: "absolute",
            top: 30,
            right: 17,
            boxShadow: "var(--box-shadow)",
          }}
          className="popover-menu"
          hidden={!showCardMenu}
          open={showCardMenu}
          onClose={(e) => handleClose(e)}
          onMouseLeave={(e) => setTimeout(() => handleClose(e), 500)}
        >
          <ul className="menu">
            <li>
              <div
                className="menu-link"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  props.setIndex(props.index);
                  props.showEditModal("active");
                }}
              >
                Edit Card
              </div>
            </li>
            <li className="no-border">
              <div
                className="menu-link"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleToggleStudy(props.card);
                }}
              >
                {props.card.in_study_pile
                  ? "Remove from Review"
                  : "Add to Review"}
              </div>
            </li>
          </ul>
        </PopoverPaper>
        {!flip ? (
          <div className="card-front">
            <p>{props.card.question}</p>
          </div>
        ) : (
          <div className="card-back">
            <h3>{props.card.answer}</h3>
          </div>
        )}
        {props.card.in_study_pile ? (
          <LocalLibrary color="secondary" className="study-icon" />
        ) : null}
      </div>
    </div>
  );
};

export default Cards;
